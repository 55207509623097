




























































































































































































































































































import {Component, Mixins, Watch} from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import PaginationMixin from "@/mixins/PaginationMixin"
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Headquarter from "@/models/Headquarter";
import HeadquarterOrder, {Status} from "@/models/HeadquarterOrder";
import Handler from "@/handlers/Handler";
import HeadquarterOrderService from "@/services/HeadquarterOrderService";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import SessionMixin from "@/mixins/SessionMixin";
import Order from "@/models/Order";
import OrderService from "@/services/OrderService";

@Component({
  components: {OrderStatusComponent}
})
export default class HeadquarterOrdersView extends Mixins(PaginationMixin, SessionMixin) {

  loading: boolean = false
  dialog: boolean = false
  get lang() { return getModule(LangModule).lang }

  headquarterOrders: MultipleItem<HeadquarterOrder> = { items: [], totalItems: 0 }

  size = 8
  picker: string = ""
  dates: string[] = [this.picker]
  selected: HeadquarterOrder[] = []

  statuses = [
    {name: this.lang.draft, value: Status.DRAFT},
    {name: this.lang.onHold, value: Status.WAITING},
    {name: this.lang.sent, value: Status.SEND},
  ]

  headers = [
    {align: "center", text: "Id" , value: "id", width: "80px"},
    {align: "center", text: this.lang.reference, value: "reference", width: "auto"},
    {align: "center", text: this.lang.status, value: "status", width: "150px"},
    { align: "center", text: this.lang.date, value: "createdAt", width: "auto" },
    { align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto" },
    { align: "center", text: this.lang.headquarter, value: "headquarter", width: "auto" },
    {align: 'center', text: this.lang.total, value: 'totalAmount', width: '100px'},
    {align: "center", text: this.lang.actions, value: "actions", width: "80px"},
  ]

  created() {
    this.refresh()
  }

  async refresh() {
    await Handler.getItems(this, this.headquarterOrders, () =>
      HeadquarterOrderService.findAll(this.page - 1, this.size, this.search)
    )
    this.setPageCount(this.headquarterOrders.totalItems!!)
  }

  onHeadquarterClicked(headquarterOrder: HeadquarterOrder) {
    this.$router.push({path: "/headquarters/" + headquarterOrder.headquarter!!.id})
  }

  isDisableEdit(item: HeadquarterOrder): boolean {
    return (item.status === Status.FINISH) || (item.status === Status.SEND)
  }

  isSelectable(item: HeadquarterOrder) {
    return ((item.status !== Status.WAITING)) && (item.headquarter) && (item.shippingAddress)
  }

  async sendHeadquarterOrders() {
    for (let item of this.selected) {
      if (item.id != null) {
        await HeadquarterOrderService.send(item.id)
        await this.refresh()
        this.selected = []
      }
    }
  }

  get sendButtonDisabled() { return this.selected.length == 0 }

  deleteOrder(id: number) {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, this.lang.deleteOrderQuestion, async () => {
          await HeadquarterOrderService.delete(id)
          await this.refresh()
        }
    ))
  }

  @Watch("page")
  onPageChanged() {
    this.refresh()
  }

}
